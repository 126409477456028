<div class="overlay-inner-wrapper">
  <header class="header-2">
    <div class="branding">
        <span class="title">{{'Objekttypen verwalten' | mrTranslate}}</span>
    </div>
    <div class="header-actions">
      <a (click)="showMangelbaum()" style="margin-top: 16px;">
        <clr-icon size="24" shape="mangelbaum" class="cursor-pointer "
          [attr.title]="'Mangelbaum' | mrTranslate">
        </clr-icon>
      </a>

      <ng-container *ngIf="seltyp == 1 || seltyp == 3">
        <div style="width: 15px;">  </div>
        <a (click)="showChecklisten()" style="margin-top: 16px;">
          <clr-icon size="24" shape="checklist" class="cursor-pointer "
            [attr.title]="'Checkliste' | mrTranslate">
          </clr-icon>
        </a>
      </ng-container>

      <ng-container *ngIf="seltyp == 2 || seltyp == 4">
        <div style="width: 15px;">  </div>
        <a (click)="showSpezifikationTemplate()" style="margin-top: 16px;">
          <clr-icon size="24" shape="spezifikation" class="cursor-pointer "
            [attr.title]="'Spezifikation' | mrTranslate">
          </clr-icon>
        </a>
        <div style="width: 15px;">  </div>
        <a (click)="openWertelisteBauwerk()" style="margin-top: 16px;">
          <clr-icon size="24" shape="bauwerke" class="cursor-pointer "
            [attr.title]="'Bauwerke' | mrTranslate">
          </clr-icon>
        </a>
      </ng-container>

      <ng-container *ngIf="seltyp == 1 || seltyp == 3">

        <div style="width: 15px;">  </div>
        <a (click)="openZaehler()" style="margin-top: 16px;">
          <clr-icon size="24" shape="zaehler" class="cursor-pointer "
            [attr.title]="'Zähler' | mrTranslate">
          </clr-icon>
        </a>

        <div style="width: 15px;">  </div>
        <a (click)="openStellkraft()" style="margin-top: 16px;">
          <clr-icon size="24" shape="stellkraft" class="cursor-pointer "
            [attr.title]="'Stellkraft' | mrTranslate">
          </clr-icon>
        </a>
      </ng-container>

      <div style="width: 15px;">  </div>
      <a (click)="openWerteliste()" style="margin-top: 16px;">
        <clr-icon size="24" shape="number-list" class="cursor-pointer "
          [attr.title]="'Mengeneinheit' | mrTranslate">
        </clr-icon>
      </a>


      <clr-dropdown>
        <button class="nav-icon" clrDropdownTrigger aria-label="toggle settings menu">
          <clr-icon shape="cog"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <a (click)="addObjekt()" clrDropdownItem>{{'Neues Objekt erstellen' | mrTranslate}}</a>
          <div *ngIf="UseKomponenten === true" class="dropdown-divider" role="separator"></div>
          <a *ngIf="UseKomponenten === true" (click)="addKomponenten()" clrDropdownItem>{{'Neue Komponente erstellen' | mrTranslate}}</a>
          <a *ngIf="selnodeid !== 0" (click)="editKomponenten()" clrDropdownItem>{{'Komponente bearbeiten' | mrTranslate}}</a>
          <a *ngIf="selnodeid !== 0" (click)="delKomponenten()" clrDropdownItem>{{'Komponente löschen' | mrTranslate}}</a>
          <div class="dropdown-divider" role="separator"></div>
          <a (click)="startExport()" clrDropdownItem>{{'Objektart exportieren' | mrTranslate}}</a>
          <a (click)="startImport()" clrDropdownItem>{{'Objektart importieren' | mrTranslate}}</a>
        </clr-dropdown-menu>
      </clr-dropdown>


      <a href="javascript://" class="nav-link nav-icon">
        <clr-icon  shape="times" (click)="close()"></clr-icon >
      </a>
    </div>
  </header>
  <div id="objekttpyWrapper" class="w-100-p padding-10-lr padding-12-b scroll-y h-60">
    <div class="clr-row nxm">
        <div class="clr-col-8">
          <h3 class="margin-11-tb">{{ "Objekte" | mrTranslate }}</h3>
          <div class="limit-height">
            <ng-container *ngIf="objekte">
              <clr-datagrid #objekteDg style="padding-top: 0px" class="datagrid-compact" [clrDgRowSelection]="true" [(clrDgSingleSelected)]="selobjekt" (clrDgSingleSelectedChange)="selectionObjektChanged($event)">
                <clr-dg-column *ngFor=" let column of objekte['columns']" [clrDgField]="column.id">{{(column.id | titlecase) | mrTranslate}}</clr-dg-column>
                <clr-dg-row *clrDgItems="let row of objekte['rows']" [clrDgItem]="row">
                  <clr-dg-action-overflow>
                    <button class="action-item" (click)="editShowObjekt(row)">{{'Bearbeiten' | mrTranslate}}</button>
                    <button [disabled]="row.anzahl !== 0" class="action-item" (click)="deleteObjek(row.id)">{{'Löschen' | mrTranslate}}</button>
                  </clr-dg-action-overflow>
                  <ng-container *ngFor="let cell of objekte['columns']">
                    <ng-container *ngIf="iconColumns.indexOf(cell.id.toLowerCase()) == -1; else iconcell">
                      <clr-dg-cell [innerHTML]="row[cell.id] | tablePrettyPrint" [title]="row[cell.id] | tablePrettyPrint | dotDotDot:30">
                    </clr-dg-cell>
                    </ng-container>
                    <ng-template #iconcell>
                      <clr-dg-cell style="color:#000">
                        <ng-container [ngSwitch]="cell.id.toLowerCase()">
                          <clr-icon *ngSwitchCase="'symbol'" class="customIcon"
                            [attr.shape]="row['symbol'] ? 'objekttyp'+row['symbol'] : 'unknown-status'" size="28">
                          </clr-icon>
                          <clr-icon *ngSwitchCase="'typ'" class="customIcon"
                            [attr.shape]="row['typ'] ? 'typ'+row['typ'] : 'unknown-status'" size="28">
                          </clr-icon>
                        </ng-container>
                      </clr-dg-cell>
                    </ng-template>
                  </ng-container>
                </clr-dg-row>
                <clr-dg-footer>
                  <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="100">{{objekte['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
                </clr-dg-footer>
              </clr-datagrid>
            </ng-container>
          </div>
          <br/>
          <h3 class="margin-11-tb">{{ "Prüfungen" | mrTranslate }}</h3>
          <clr-datagrid #pruefungDg *ngIf="selobjekt && pruefungen?.columns?.length"
            style="padding-top: 0px" class="datagrid-compact"
            [(clrDgSingleSelected)]="selpruefung"
            (clrDgSingleSelectedChange)="selectionPruefungChanged($event)">
            <clr-dg-column *ngFor=" let column of pruefungen['columns']" [clrDgField]="column.id" [hidden]="column.id | hideIDColumns:['otypid']">{{(column.id | titlecase) | mrTranslate}}</clr-dg-column>
            <clr-dg-row *clrDgItems="let row of pruefungen['rows']" [clrDgItem]="row">
              <clr-dg-action-overflow>
                <button class="action-item" (click)="editShowObjekt(row)">{{'Bearbeiten' | mrTranslate}}</button>
                <button [disabled]="row.Anzahl !== '0'" class="action-item" (click)="deleteObjek(row.ID)">{{'Löschen' | mrTranslate}}</button>
              </clr-dg-action-overflow>
              <clr-dg-cell *ngFor="let cell of pruefungen['columns']" [hidden]="cell.id | hideIDColumns:['otypid']"
                [innerHTML]="row[cell.id] | dotDotDot:30 | tablePrettyPrint"
                [title]="row[cell.id] | tablePrettyPrint">
              </clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
              <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="4">{{pruefungen['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
          <br>
          <div *ngIf="UseKomponenten === true" class="content-area" style="height:200px">
            <h3  class="margin-11-tb">{{ "Komponenten" | mrTranslate }}</h3>
            <div class="content-container">
              <div class="content-area">
                <clr-tree [(clrTreeSelected)]="selTreeNote">
                  <clr-tree-node *clrRecursiveFor="let item of root; getChildren: getChildren" [(clrExpanded)]="item.expanded">

                    <a class="clr-treenode-link" routerLinkActive="active" (click)='treeClick(item)' (dblclick)='treeDoubleClick(item)'>{{item.name}}</a>
                    <!-- (click)="selectTreeNode(item)" -->
                    <!-- {{item.name}} -->
                  </clr-tree-node>
                </clr-tree>
                <br>
                <br>
              </div>
            </div>
            <br>
          </div>
        </div>

        <div class="clr-col-4">
          <div class="clr-row nxm">
            <div class="clr-col">
              <h3 class="margin-11-tb">{{ "Eigenschaften" | mrTranslate }}</h3>
            </div>
            <div class="clr-col-auto">
              <button class="btn btn-primary btn-sm margin-0-t" type="submit" [disabled]="trial" (click)="saveSysObjectOptions()">{{'speichern' | mrTranslate}}</button>
            </div>
          </div>
          <clr-stack-view [formGroup]="sysooptGroup" style="margin-top:5px" *ngIf="selpruefung !== undefined">

            <clr-stack-block [clrSbExpanded]="true">
              <clr-stack-label>{{ "Ansicht" | mrTranslate }}</clr-stack-label>

              <clr-stack-block *ngIf="seltyp === 1 || seltyp === 3">
                <clr-stack-label>{{ "Bahnsteigmessung" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_F_Bahnsteig" />
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block *ngIf="seltyp === 1 || seltyp === 3">
                <clr-stack-label>{{ "Bahnübergang Sichtdreieck" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_F_BUESicht" />
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block *ngIf="seltyp === 1 || seltyp === 3">
                <clr-stack-label>{{ "Einbauhistorie" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_F_Einbauhistory" />
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block *ngIf="seltyp === 1 || seltyp === 3">
                <clr-stack-label>{{ "Komponentenverwaltung" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_O_UseKomponenten" (change)="changedKomponenten($event)"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Linienbelegung" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_F_Linienbelegung" />
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Logbuch" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_F_Logbuch"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block *ngIf="seltyp === 1 || seltyp === 3">
                <clr-stack-label>{{ "MessReg Messdaten verwalten" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_O_UseMessReg"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block *ngIf="seltyp === 1 || seltyp === 3">
                <clr-stack-label>{{ "Mangelskizzen" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_F_Drawing"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block *ngIf="seltyp === 2 || seltyp === 4">
                <clr-stack-label>{{ "Naturschutz" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_F_Naturschutz" />
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block *ngIf="seltyp === 1 || seltyp === 3">
                <clr-stack-label>{{ "Schienenprofil" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_O_UseRailProfil"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block *ngIf="seltyp === 1 || seltyp === 3">
                <clr-stack-label>{{ "Stellkraftmessung" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_F_Stellkraftmessung"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block *ngIf="seltyp === 2 || seltyp === 4">
                <clr-stack-label>{{ "Trassierung" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_F_Trassierung"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block *ngIf="seltyp === 1 || seltyp === 3">
                <clr-stack-label>{{ "Zähler" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_F_Zaehler"/>
                </clr-stack-content>
              </clr-stack-block>

            </clr-stack-block>

            <clr-stack-block>
              <clr-stack-label>{{ "Export" | mrTranslate }}</clr-stack-label>


              <clr-stack-block>
                <clr-stack-label>{{ "Fehlerklassen" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_E_Fehlerklasse"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Fehlerklassen Symbol" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_E_FK_ImageToDok"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block *ngIf="seltyp === 4">
                <clr-stack-label>{{ "Schienenhöhe" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_E_Track_Spez_Schienenhoehe"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block *ngIf="seltyp === 4">
                <clr-stack-label>{{ "Schwellennote / Schwellenjahr" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>

                  <clr-radio-container clrInline>
                    <clr-radio-wrapper>
                      <input type="radio" clrRadio formControlName="Opt_E_Track_Spez_Schwellenjahr" [value]="0" />
                      <label>{{'kein' | mrTranslate}}</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                      <input type="radio" clrRadio formControlName="Opt_E_Track_Spez_Schwellenjahr" [value]="1" />
                      <label>{{'Jahr' | mrTranslate}}</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                      <input type="radio" clrRadio formControlName="Opt_E_Track_Spez_Schwellenjahr" [value]="2" />
                      <label>{{'Note' | mrTranslate}}</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                      <input type="radio" clrRadio formControlName="Opt_E_Track_Spez_Schwellenjahr" [value]="3" />
                      <label>{{'Jahr + Note' | mrTranslate}}</label>
                    </clr-radio-wrapper>
                  </clr-radio-container>


                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Sperrung Symbol" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_E_Sperr_ImageToDok"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Spezifikation" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_E_Spez_ListToDok" />
                </clr-stack-content>
              </clr-stack-block>

            </clr-stack-block>

            <clr-stack-block *ngIf="seltyp === 4" [clrSbExpanded]="true">
              <clr-stack-label>{{ "Spezifikation (Optionsfelder)" | mrTranslate }}</clr-stack-label>

              <clr-stack-block>
                <clr-stack-label>{{ "Dämmung" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_I_Track_Spez_Option_Daemmung"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Einbaujahr" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_I_Track_Spez_Option_Einbaujahr"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Eindeckung links" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_I_Track_Spez_Option_ZusatzI"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Eindeckung rechts" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_I_Track_Spez_Option_ZusatzII"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Unterbau" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_I_Track_Spez_Option_Unterbau" />
                </clr-stack-content>
              </clr-stack-block>

            </clr-stack-block>

            <clr-stack-block *ngIf="seltyp === 4" [clrSbExpanded]="true">
              <clr-stack-label>{{ "Spezifikation (Pflichtfelder)" | mrTranslate }}</clr-stack-label>

              <clr-stack-block>
                <clr-stack-label>{{ "Schienenhöhe" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_I_Track_Spez_Schienenhoehe"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Schienenwalzjahr" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_I_Track_Spez_Schienenjahr"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Schwellenjahr" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_I_Track_Spez_Schwellenjahr"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Schwellennote" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_I_Track_Spez_SchwellenNote"/>
                </clr-stack-content>
              </clr-stack-block>

            </clr-stack-block>




            <clr-stack-block [clrSbExpanded]="true">
              <clr-stack-label>{{ "Pflichtfelder" | mrTranslate }}</clr-stack-label>
              <clr-stack-block>
                <clr-stack-label>{{ "Abteilung" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_I_Zustaend_Abt"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Anlagenklasse" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_I_Anlagenklasse"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block *ngIf="seltyp === 1 || seltyp === 3">
                <clr-stack-label>{{ "Checklisten bestätigen" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_I_CheckList" />
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Fehlerklassen" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_I_Fehlerklasse"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Grundinstandsetzung" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_I_Gundinst"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Passbild" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_I_PassBild"/>
                </clr-stack-content>
              </clr-stack-block>

            </clr-stack-block>


            <clr-stack-block>
              <clr-stack-label>{{ "Optionen" | mrTranslate }}</clr-stack-label>
              <clr-stack-block>
                <clr-stack-label>{{ "alte Bildpfade löschen (Neue Inspektion)" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_I_Del_Old_Image"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Prüfintervall = Messintervall" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_I_PruefIntIsMessInt"/>
                </clr-stack-content>
              </clr-stack-block>

            </clr-stack-block>

            <clr-stack-block>
              <clr-stack-label>{{ "Prüfklalender" | mrTranslate }}</clr-stack-label>
              <clr-stack-block>
                <clr-stack-label>{{ "Toleranz" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input clrInput type="number" formControlName="Opt_K_Kalender"/>
                </clr-stack-content>
              </clr-stack-block>

            </clr-stack-block>

            <clr-stack-block>
              <clr-stack-label>{{ "Zustand" | mrTranslate }}</clr-stack-label>

              <clr-stack-block>
                <clr-stack-label>{{ "ordnungsgemäßer Zustand" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input type="checkbox" clrCheckbox formControlName="Opt_E_OrdZustand"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Text Zustand in Ordnung" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input clrInput type="text" formControlName="Opt_E_OrdZustand_TXT_IO" style="width:300px"/>
                </clr-stack-content>
              </clr-stack-block>

              <clr-stack-block>
                <clr-stack-label>{{ "Text Zustand nicht in Ordnung" | mrTranslate }}</clr-stack-label>
                <clr-stack-content>
                  <input clrInput type="text" formControlName="Opt_E_OrdZustand_TXT_NIO" style="width:300px"/>
                </clr-stack-content>
              </clr-stack-block>

            </clr-stack-block>

          </clr-stack-view>
        </div>


        <!-- <div class="clr-col-4">
          <h3 class="margin-11-tb">{{ "Eigenschaften" | mrTranslate }}</h3>
          <clr-datagrid #grid style="padding-top: 0px" class="datagrid-compact">
            <clr-dg-column>{{"Bezeichnung" | mrTranslate}}</clr-dg-column>
            <clr-dg-row *clrDgItems="let prop of properties" [clrDgItem]="prop.id">
              <clr-dg-cell>{{ prop.Bezeichnung }} </clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
              <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="15">{{properties.length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
        </div> -->

    </div>
    <app-basedatamodal></app-basedatamodal>
  </div>
</div>


<clr-modal [(clrModalOpen)]="editObjektModal" [clrModalClosable]="true" [clrModalSize]="'lg'">
  <h3 class="modal-title">{{modalObjekttitel}}</h3>
  <div class="modal-body">
    <form clrForm clrLayout="horizontal">
      <clr-radio-container clrInline class="margin-0-t">
        <label style="display: table;">Typ</label>
        <clr-radio-wrapper>
          <input type="radio" clrRadio name="typ" [value]="1" #_typ checked="true" />
          <label>
            <clr-icon shape="typ1" size="32"></clr-icon>
          </label>
          <clr-icon class="customIcon" [attr.shape]="'typ1'" size="28"></clr-icon>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input type="radio" clrRadio name="typ" [value]="2" #_typ/>
          <label>
            <clr-icon shape="typ2" size="32"></clr-icon>
          </label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input type="radio" clrRadio name="typ" [value]="3" #_typ/>
          <label>
            <clr-icon shape="typ3" size="32"></clr-icon>
          </label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input type="radio" clrRadio name="typ" [value]="4" #_typ/>
          <label>
            <clr-icon shape="typ4" size="32"></clr-icon>
          </label>
        </clr-radio-wrapper>
      </clr-radio-container>
      <clr-input-container>
        <label>{{'Name' | mrTranslate}}</label>
        <input clrInput name="Name" #_name/>
      </clr-input-container>
      <clr-input-container>
        <label>{{'Bezeichnung' | mrTranslate}}</label>
        <input class="clr-input-short" clrInput name="Bezeichnung" #_bezeichnung/>
      </clr-input-container>
      <br/>
      <h4 class="margin-11-tb">{{ "Symbole" | mrTranslate }}</h4>
      <clr-datagrid style="padding-top: 0px" class="datagrid-compact" [(clrDgSingleSelected)]="selsymbol" (clrDgSingleSelectedChange)="selectionSymbolChanged($event)">
        <clr-dg-column>Symbol</clr-dg-column>
        <clr-dg-column>Name</clr-dg-column>

        <clr-dg-row *ngFor="let ic of symbols" [clrDgItem]="ic" [clrDgSelected]="selsymbol">
          <clr-dg-cell style="color:#000">
            <!-- <clr-icon shape="objekttyp1" size="32"></clr-icon> -->
              <clr-icon
                [attr.shape]="ic.id ? 'objekttyp'+ic.id : 'unknown-status'" size="28">
              </clr-icon>
          </clr-dg-cell>

          <clr-dg-cell>{{ic.name}}</clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>{{symbols.length}} Symbole</clr-dg-footer>
      </clr-datagrid>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary"  (click)="updateObjekt()">{{ "OK" | mrTranslate }}</button>
    <button type="button" class="btn" (click)="editObjektModal = false">{{ "Abbrechen" | mrTranslate }}</button>
  </div>
</clr-modal>


<clr-modal [(clrModalOpen)]="editKomponentenModal" [clrModalClosable]="true">
  <h3 class="modal-title">{{modalKomponententtitel}}</h3>
  <div class="modal-body">
    <form clrForm clrLayout="horizontal">
      <clr-input-container>
        <label>{{'Bezeichnung' | mrTranslate}}</label>
        <input clrInput name="Bezeichnung" #_komptext/>
      </clr-input-container>
      <br/>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary"  (click)="updateKomponenten()">{{ "OK" | mrTranslate }}</button>
    <button type="button" class="btn" (click)="editKomponentenModal = false">{{ "Abbrechen" | mrTranslate }}</button>
  </div>
</clr-modal>



<clr-modal [(clrModalOpen)]="importObjektModal" [clrModalClosable]="false">
  <h3 class="modal-title">{{ "Objekt importieren" | mrTranslate }}</h3>
  <div class="modal-body">
    <form clrForm>
      <div class="clr-row">
        <div class="clr-control-container clr-col-md-12">
          <div class="clr-file-wrapper">
            <label for="multiSQL" class="clr-control-label" style="padding-left: 0px;"><span class="btn btn-sm">{{ "Datei auswählen" | mrTranslate }}</span></label>
            <input type="file" id="multiSQL" #fileUploader name="fileUploader" placeholder="" class="button-input clr-file" accept="application/json"
              (change)="onFileSelect('multi',$event.target.files[0])">
          </div>
          <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
          <span class="clr-subtext" *ngIf="importObjektFile">{{importObjektFile.name}}</span>
        </div>
      </div>
      <br/>
      <div class="clr-row">
        <div class="clr-control-container clr-col-md-12">
          <label for="multiSQL" class="clr-control-label clr-col-12 clr-col-md-12" style="padding-left: 0px;" *ngIf="doUpload">{{info}}</label>
        </div>
      </div>
      <div class="clr-row">
        <div class="clr-control-container clr-col-md-12">
          <clr-progress-bar [clrValue]="importProgessValue" clrLabeled *ngIf="doUpload"></clr-progress-bar >
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer" *ngIf="doUpload != true">
    <button class="btn btn-primary" type="submit" (click)="sendDBFile('multi')" *ngIf="importjsonObj">
      <clr-icon shape="upload-cloud"></clr-icon> {{ "Import" | mrTranslate }}
    </button>
    <button type="button" class="btn" (click)="exitImport()">{{ "Beenden" | mrTranslate }}</button>
  </div>
</clr-modal>

